export const NAVBAR_LINKS = [
  {
    text: 'Resume',
    href: `/resume.pdf`,
  },
  {
    text: 'About',
    href: '/'
  },
  {
    text: 'Projects',
    href: '/#projects',
  },
]
